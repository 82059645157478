<template>
  <b-card no-body>
    <b-card-header class="pb-50">
      <h5>
        {{ $t('label_filters') }}
      </h5>
    </b-card-header>
    <b-card-body>
      <b-row>

        <b-col
          cols="12"
          md="3"
          class=" mb-1 date-range-col"
        >
          <label>{{ $t('label_date_agreement_conclusion') }}</label>

          <div class="date-range-filter mb-2">
            <custom-date-picker
                    class="date--picker no-label"
                    :locale="$store.state.appConfig.locale"
                    :placeholder="$t('label_DD-MM-YYYY')"
                    :value="dateFromFilter"
                    @input="(val) => {$emit('update:dateFromFilter', val)}"
            >
              <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
            </custom-date-picker>

            <div class="date-separator" />
            <custom-date-picker
                    class="date--picker no-label"
                    :locale="$store.state.appConfig.locale"
                    :placeholder="$t('label_DD-MM-YYYY')"
                    :value="dateToFilter"
                    @input="(val) => {$emit('update:dateToFilter', val)}"
            >
              <template #clear-btn="{ vm }"><feather-icon icon="XIcon" size="14"/></template>
            </custom-date-picker>

          </div>

          <label>{{ $t('label_procurations_status') }}</label>
          <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :value="procStatusFilter"
                  :options="procStatusOptions"
                  class="w-100"
                  :placeholder="$t('label_select')"
                  :reduce="val => val.value"
                  @input="(val) => $emit('update:procStatusFilter', val)"
          >
            <template v-slot:option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.label) }}</span>
            </template>
            <template v-slot:selected-option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.label) }}</span>
            </template>
            <template v-slot:no-options="option">
              {{$t('label_no_items')}}
            </template>
          </v-select>
          <!--</b-form-group>-->
        </b-col>
        <b-col

          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
        <div class="mb-2">
          <label>{{ $t('label_deal_status') }}</label>
          <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :value="statusFilter"
                  :options="statusOptions"
                  class="w-100"
                  :placeholder="$t('label_select')"
                  :reduce="val => val.id"
                  @input="(val) => $emit('update:statusFilter', val)"
          >
            <template v-slot:option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.translation_index) }}</span>
            </template>
            <template v-slot:selected-option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.translation_index) }}</span>
            </template>
            <template v-slot:no-options="option">
              {{$t('label_no_items')}}
            </template>
          </v-select>
        </div>
          <label>{{ $t('label_stage') }}</label>
          <v-select
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :value="stageFilter"
                  :options="stageOptions"
                  class="w-100"
                  :placeholder="$t('label_select')"
                  :reduce="val => val.id"
                  @input="(val) => $emit('update:stageFilter', val)"
          >
            <template v-slot:option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.translation_index) }}</span>
            </template>
            <template v-slot:selected-option="option">
              <!--<span :class="option.icon"></span>-->
              <span class="">{{ $t(option.translation_index) }}</span>
            </template>
            <template v-slot:no-options="option">
              {{$t('label_no_items')}}
            </template>
          </v-select>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('label_solicitor') }}</label>
          <infinity-scroll

                  selected-prop="id"
                  url="/solicitor"
                  :placeholder="$t('label_select')"
                  @input="(val) => $emit('update:solicitorFilter', val)"
          >
            <template #label="{item}">{{ item.name }}</template>

          </infinity-scroll>

        </b-col>
        <b-col
          cols="12"
          md="3"
          class="mb-md-0 mb-2"
        >
          <label>{{ $t('label_recommended') }}</label>

          <!--***********INFINITY**************************************************************************************8-->


              <infinity-scroll

                      selected-prop="id"
                      url="/agent"
                      :placeholder="$t('label_select')"
                      @input="(val) => $emit('update:agentFilter', val)"
              >
                <template #label="{item}">{{ item.name }}</template>

              </infinity-scroll>

        </b-col>

      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>

    import {
        BCard, BCardHeader, BCardBody, BFormCheckbox,
    } from 'bootstrap-vue'
    import vSelect from 'vue-select'

    import  infinityScroll from '@/views/components/infinityScroll'

    export default {
        components: {

            BCard,
            BCardHeader,
            BCardBody,
            BFormCheckbox,

            vSelect,

          infinityScroll

        },

        props: {

            dateFromFilter: {
                type: [String, null],
                default: null,
            },

            dateToFilter: {
                type: [String, null],
                default: null,
            },

            statusOptions: {
                type: Array,
                required: true,
            },
            statusFilter: {
                type: [String, null],
                default: null,
            },
            stageOptions: {
                type: Array,
                required: true,
            },
            stageFilter: {
                type: [String, null],
                default: null,
            },
            procStatusOptions:{
                type: Array,
                required: true,
            },
            procStatusFilter:{
                type: [String, null],
                default: null,
            },

            solicitorFilter: {
                type: [String, null],
                default: null,
            },

            agentFilter: {
                type: [String, null],
                default: null,
            },

        },

    }
</script>


